import { lazy } from 'react';
const itemReturnSec = lazy(() => import('./itemReturnSec'));

const itemReturnSecRoutes = [
  {
    path: '/itemReturnSec',
    component: itemReturnSec
  }
];

export default itemReturnSecRoutes;
