import { lazy } from 'react';
const auditplanDashboard = lazy(() => import('./auditplanDashboard'));

const auditplanDashboardRoutes = [
  {
    path: '/auditplanDashboard',
    component: auditplanDashboard
  }
];

export default auditplanDashboardRoutes;
