import { lazy } from 'react';
const leave = lazy(() => import('./leave'));
const leaveNew = lazy(() => import('./leaveNew'));

const leaveRoutes = [
  {
    path: '/leave',
    component: leaveNew
  }
];

export default leaveRoutes;