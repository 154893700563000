import { lazy } from 'react';

const itemRetrunUser = lazy(() => import('./itemRetrunUser'));

const itemRetrunUserRoutes = [
  {
    path: '/itemRetrunUser',
    component: itemRetrunUser
  }
];

export default itemRetrunUserRoutes;