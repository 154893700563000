import { lazy } from "react";

const Journalentry = lazy(() => import("./Journalentry"));
const journalentryNew = lazy(() => import("./journalentryNew"));

const journalentryRoutes = [
  {
    path: "/journalentry",
    component: journalentryNew,
  },
];

export default journalentryRoutes;
