import { lazy } from 'react';
const timesheetInquiry = lazy(() => import('./timesheetInquiry'));

const timesheetinquiryRoutes = [
  {
    path: '/timesheetinquiry',
    component: timesheetInquiry
  }
];

export default timesheetinquiryRoutes;