import { lazy } from "react";
const itemDispatch = lazy(() => import('./itemDispatch'));


const itemDispatchRoutes = [
    {
        path: '/itemDispatch',
        component: itemDispatch
    }
]

export default itemDispatchRoutes;