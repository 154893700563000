import { lazy } from 'react';
const itemDispatchApp = lazy(() => import('./itemDispatchApp'));

const itemDispatchAppRoutes = [
  {
    path: '/dispapp',
    component: itemDispatchApp
  }
];

export default itemDispatchAppRoutes;
