import instance from '../../config/axiosConfig';
export const UPDATE_APPROVAL_COUNT = 'UPDATE_APPROVAL_COUNT';

export const updateApprovalCount = (count) => ({
  type: UPDATE_APPROVAL_COUNT,
  payload: count
});

export const fetchApprovalCount = () => {
  return async (dispatch) => {
    try {
      const { data } = await instance.get(
        `itmdispatch/approvals?companyId=${localStorage.getItem('CompaneyId')}`
      );
      console.log(data);
      if (data && data.length > 0) {
        dispatch(updateApprovalCount(data.length));
      } else {
        dispatch(updateApprovalCount(0));
      }
    } catch (error) {
      console.log(error);
      dispatch(updateApprovalCount(0));
    }
  };
};
