import { lazy } from 'react';
const cmprephis = lazy(() => import('./cmprephis'));

const cmprephisRoutes = [
  {
    path: '/cmprephis',
    component: cmprephis
  }
];

export default cmprephisRoutes;
