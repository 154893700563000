import { lazy } from 'react';
const fabricstockwastage = lazy(() => import('./fabricstockwastage'));

const fabricstockwastageRoutes = [
  {
    path: '/fabricstockwastage',
    component: fabricstockwastage
  }
];

export default fabricstockwastageRoutes;
