import { lazy } from 'react';
const leaveApp = lazy(() => import('./leaveApp'));

const leaveAppRoutes = [
  {
    path: '/leaveapp',
    component: leaveApp
  }
];

export default leaveAppRoutes;
