import { lazy } from 'react';
const auditplan = lazy(() => import('./auditplan'));

const auditplanRoutes = [
  {
    path: '/auditplan',
    component: auditplan
  }
];

export default auditplanRoutes;