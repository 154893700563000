import { lazy } from 'react';
const cmpmasinquiry = lazy(() => import('./cmpmasinquiry'));

const cmpmasinquiryRoutes = [
  {
    path: '/cmpmasinquiry',
    component: cmpmasinquiry
  }
];

export default cmpmasinquiryRoutes;