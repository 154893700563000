import { lazy } from 'react';
const qualitymanual = lazy(() => import('./quality-manual.jsx'));

const qualitymanualRoutes = [
  {
    path: '/sop/quality-manual',
    component: qualitymanual
  }
];

export default qualitymanualRoutes;
