import { lazy } from 'react';
const poreversal = lazy(() => import('./poreversal'));

const poreversalRoutes = [
  {
    path: '/poreversal',
    component: poreversal
  }
];

export default poreversalRoutes;