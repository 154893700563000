import { lazy } from 'react';
const sampleDispatch = lazy(() => import('./sampleDispatch'));

const sampleDispatchRoutes = [
  {
    path: '/sampleDispatch',
    component: sampleDispatch
  }
];

export default sampleDispatchRoutes;
