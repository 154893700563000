import { lazy } from 'react';
const CmpMaster = lazy(() => import('./cmpmaster'));

const cmpmasterRoutes = [
  {
    path: '/cmpmaster',
    component: CmpMaster
  }
];

export default cmpmasterRoutes;
